import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Button from "../components/Common/Button";
import Cookies from "js-cookie";
import NavDropdown from "react-bootstrap/NavDropdown";
import { MdLanguage } from "react-icons/md";

import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import i18n from "../../i18n";

import { useTranslation, Trans } from "react-i18next";
import { Offcanvas, Nav, Form } from "react-bootstrap";
const handleCase = (item) => {
  switch (item) {
    case "/pricing":
    case "/our-services":
    case "/product-research":
    case "/order":
    case "/":
      return true;
    default:
      return false;
  }
};
const Header = () => {
  const currentLocale = Cookies.get("i18next") || "it";
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleToggle = () => setShow(!show);
  // console.log(show, "first time");
  const [open, setOpen] = useState("#fff");
  const [language, setLanguage] = useState(currentLocale);

  // const [screenSize, getDimension] = useState({
  //   dynamicWidth: window.innerWidth,
  //   dynamicHeight: window.innerHeight,
  // });
  // const setDimension = () => {
  //   getDimension({
  //     dynamicWidth: window.innerWidth,
  //     dynamicHeight: window.innerHeight,
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", setDimension);
  //   if (screenSize.dynamicWidth < 900) {
  //     setShow(true);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", setDimension);
  //   };
  // }, []);

  const handleLanguage = (event) => {
    event = event.replace("#", "");

    setLanguage(event);

    i18n.changeLanguage(event);
  };

  return (
    <>
      <Navbar
        key={"md"}
        bg="transparent"
        expand="lg"
        style={{ position: "absolute", zIndex: 1, width: "100%" }}
        className="mb-3"
      >
        <Container>
          <Navbar.Brand style={{ flexGrow: 1 }}>
            <Link to="/">
              <p
                className={`logo text-${
                  open !== "#000" ||
                  location.pathname === "/order" ||
                  location.pathname === "/"
                    ? "white"
                    : "dark"
                }`}
              >
                {t("appname")}
              </p>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${"md"}`}
            style={{ color: "#fff", background: open !== "#000" ? "#fff" : "" }}
            onClick={handleToggle}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"md"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
            placement="end"
            show={show}
          >
            <Offcanvas.Header closeButton show={show} onHide={handleClose}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"md"}`}>
                <Link to="/">
                  <p className={`logo text-white`}>{t("appname")}</p>
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body show={show}>
              <Nav className="flex-grow-1 pe-3">

              <Nav className="flex-grow-1 pe-3 align-items-center">
  {t("headers", { returnObjects: true })?.map((item) =>
    item.dropdown ? (
      
      // Dropdown per "Prodotti"
      <NavDropdown
      key={item.name}
      title={item.name}
      id={`nav-dropdown-${item.name}`}
      className="nav-link btn-scale mx-2"
    >
      {item.dropdown.map((subItem) => (
        <NavDropdown.Item
          key={subItem.link}
          href={subItem.link.startsWith("http") ? subItem.link : undefined} // Link diretto solo per esterni
          style={{
            color: "#000", // Colore predefinito
          }}
          onClick={(e) => {
            if (subItem.link.startsWith("http")) {
              // Per i link esterni, lascia che il browser li gestisca
              return;
            }
    
            // Per i link interni, usa navigate senza ricaricare la pagina
            e.preventDefault(); // Evita il comportamento predefinito
            setShow(false); // Nasconde il menu di navigazione
            navigate(subItem.link); // Naviga alla pagina corretta
          }}
        >
          {subItem.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
    
    ) : (

      // Link normali
          <li key={item.link} className="nav-item mx-2">
      <a
        href={item.link} // Link diretto
        rel="noopener noreferrer" // Per sicurezza, anche se non è essenziale per link interni
        className={`nav-link btn-scale ${handleCase(location?.pathname) ? "text-white" : ""}`}
        style={{
          color: handleCase(location?.pathname) ? "#fff" : "#000",
        }}
        onClick={() => {
          setShow(false); // Nasconde il menu di navigazione
        }}
      >
        {item.name}
      </a>
    </li>
        )
      )}
    </Nav>

  {/* Dropdown delle lingue */}
  <NavDropdown
    id="nav-dropdown-languages"
    title={
      <MdLanguage
        size={24}
        color={
          open !== "#000" ||
          location.pathname === "/order" ||
          location.pathname === "/"
            ? "#fff"
            : "#000"
        }
      />
    }
    className="nav-link btn-scale mx-2 dropdown-language"
    menuVariant={handleCase(location?.pathname) ? "light" : "dark"}
    onSelect={handleLanguage}
    style={{
      color: handleCase(location?.pathname) ? "#fff" : "#000",
    }}
  >
    <NavDropdown.Item href="#it">Italiano</NavDropdown.Item>
    <NavDropdown.Item href="#en">English</NavDropdown.Item>
  </NavDropdown>
  
 {/* Pulsante Ordina Ora */}
 <li className="nav-item mx-2">
    <Button
      Text={<Trans component={{ br: <br /> }}>FooterOrderNow</Trans>}
      URL={"arrow-right"}
      onClick={() => {
        setOpen("#fff");
        navigate("/order");
        if (show) {
          handleToggle();
        }
      }}
      hover="btn-hover"
      style={{
        padding: "10px 24px",
        height: "45px", // Altezza simile agli altri elementi
        display: "flex",
        alignItems: "center",
      }}
    />
  </li>
</Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
